import React, { useState, useEffect } from 'react';
import { Typography, Modal, Radio } from 'antd';
import { sendToBankEmail } from '../../../../../../store/banks/actions';
import { useDispatch } from 'react-redux';
import EmailPageSendBank from './EmailPageSendBank';
import TableSantander from './TableSantander';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import LoaderLogo from '../../../../../../Components/Layout/LoaderLogo';
import TableEvo from './TableEvo';
import axios from 'axios';
import authClient from '../../../../../../Auth/Auth';
import env from '../../../../../../environment';
import EmailPageSendBankSabadell from './EmailPageSendBankSabadell';
import TableUci from './TableUCI';
import TableCaixaBankApi from './TableCaixaBankApi';
import TableBankinter from './TableBankinter';
import TableSabadell from './TableSabadell';

const { Text } = Typography;

const ControllerApiEmailCreate = ({
	onCreate,
	onCancel,
	mortgageName,
	mortgage,
	channel,
	mortgageId,
	bankRequestId,
	bankRequest,
	mortgageData
}) => {
	const [modalShow, setModalShow] = useState(false);
	const [valueRadio, setValueRadio] = useState(undefined);
	const location = useLocation();
	const [loading, setLoading] = useState(undefined);
	const idBank = location && location.state !== undefined ? location.state.bankId : undefined;
	let dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();
	const [type, setType] = useState(undefined);

	useEffect(() => {
		if (
			idBank === 'a026N000000whCdQAI' ||
			idBank === 'a021t00000KGFCuAAP' ||
			idBank === 'a026N000000whOKQAY' ||
			idBank === 'a026N000000whFmQAI' ||
			idBank === 'a021t00000Jm4QOAAZ' ||
			(idBank === 'a021t000009OgpJAAS' && mortgageData.managementCode === 'gibobs')
		) {
			setLoading(true);
			setModalShow(true);
			dispatch(sendToBankEmail(false));
			setValueRadio(2);
		} else {
			setLoading(false);
			setModalShow(false);
			dispatch(sendToBankEmail(true));
			setValueRadio(1);
		}
	}, []);

	const handleChange = (value) => {
		if (value.target.value === 1) {
			dispatch(sendToBankEmail(true));
			setValueRadio(value.target.value);
		} else {
			dispatch(sendToBankEmail(false));
			setValueRadio(value.target.value);
		}
	};

	const emailOrApi = () => {
		setModalShow(!modalShow);
		setLoading(false);

		if (idBank === 'a026N000000whFmQAI' && valueRadio === 2) {
			navigate(`/operations/mortgages/${param.mortgageId}/table-caixa-api`, {
				state: {
					bankId: idBank,
					bankRequestId: bankRequestId,
					bankRequest: bankRequest,
					typeBankrequest: undefined
				}
			});
		}

		if (idBank === 'a021t00000KGFCuAAP' && valueRadio === 1) {
			navigate(`/operations/mortgages/${param.mortgageId}/email-send-evo`, {
				state: {
					bankId: idBank,
					bankRequestId: bankRequestId,
					bankRequest: bankRequest,
					typeBankrequest: undefined
				}
			});
		}

		if (valueRadio === 2) {
			axios
				.post(
					`${env.api.url}/banks/bank-request/update/${bankRequestId}`,
					{ type: 'API' },
					{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
				)
				.then((response) => {
					setType(response.data.data.type);
					if (idBank === 'a021t00000KGFCuAAP') {
						navigate(`/operations/mortgages/${param.mortgageId}/table-evo`, {
							state: {
								bankId: idBank,
								bankRequestId: bankRequestId,
								bankRequest: bankRequest,
								typeBankrequest: response.data.data.type
							}
						});
					}
				});
		}
	};

	const renderDisabled = () => {
		if (
			idBank === 'a021t00000KGFCuAAP' ||
			idBank === 'a026N000000whCdQAI' ||
			idBank === 'a026N000000whOKQAY' ||
			idBank === 'a026N000000whFmQAI' ||
			idBank === 'a021t00000Jm4QOAAZ' ||
			(idBank === 'a021t000009OgpJAAS' && mortgageData.managementCode === 'gibobs')
		) {
			return false;
		} else {
			return true;
		}
	};

	const renderName = (idBank) => {
		if (idBank === 'a026N000000whCdQAI') {
			return 'Santander';
		} else if (idBank === 'a026N000000whOKQAY') {
			return 'Unión de Créditos Inmobiliarios';
		} else if (idBank === 'a021t00000Jm4QOAAZ') {
			return 'Bankinter';
		} else if (idBank === 'a026N000000whFmQAI') {
			return 'CaixaBank';
		} else if (idBank === 'a021t000009OgpJAAS') {
			return 'Sabadell';
		} else {
			return 'Evo';
		}
	};

	if (loading) {
		return (
			<>
				<div
					style={{
						textAlign: 'center',
						height: '80vh',
						backgroundColor: 'white'
					}}>
					<LoaderLogo />
				</div>

				<Modal
					className="gb-modal-style-common modal-task-button-style"
					cancelButtonProps={{ className: 'button-secundary-gibobs', style: { display: 'none' } }}
					okButtonProps={{ className: 'button-primari-gibobs', style: { width: '96px' } }}
					title={'Elige como enviar'}
					open={modalShow}
					width={591}
					onOk={() => emailOrApi()}
					okText={'Aceptar'}
					closable={false}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
							Tienes que elegir una opcion de envio a {renderName(idBank)}.
						</Text>
						<Radio.Group
							defaultValue={valueRadio}
							onChange={(e) => handleChange(e)}
							style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 16 }}>
							<Radio value={1} disabled={idBank === 'a021t00000KGFCuAAP' ? true : false}>
								Via email
							</Radio>
							<Radio disabled={renderDisabled()} value={2} style={{ marginTop: 16 }}>
								Via Api
							</Radio>
						</Radio.Group>
					</div>
				</Modal>
			</>
		);
	} else {
		if (loading === false && valueRadio) {
			let content = (
				<EmailPageSendBank
					onCreate={onCreate}
					onCancel={onCancel}
					mortgageName={mortgageName}
					mortgage={mortgage}
					channel={channel}
					mortgageId={mortgageId}
					mortgageData={mortgageData}></EmailPageSendBank>
			);
			if (valueRadio === 2 && idBank === 'a026N000000whCdQAI') {
				content = (
					<TableSantander
						mortgageId={mortgageId}
						type={channel}
						mortgage={mortgage}></TableSantander>
				);
			}
			if (valueRadio === 2 && idBank === 'a021t00000KGFCuAAP') {
				content = <TableEvo mortgageId={mortgageId} type={channel} mortgage={mortgage}></TableEvo>;
			}
			if (valueRadio === 2 && idBank === 'a026N000000whOKQAY') {
				content = <TableUci mortgageId={mortgageId} type={channel} mortgage={mortgage}></TableUci>;
			}
			if (valueRadio === 2 && idBank === 'a026N000000whFmQAI') {
				content = (
					<TableCaixaBankApi
						mortgageId={mortgageId}
						type={channel}
						mortgage={mortgage}></TableCaixaBankApi>
				);
			}
			if (valueRadio === 2 && idBank === 'a021t00000Jm4QOAAZ') {
				content = (
					<TableBankinter
						mortgageId={mortgageId}
						type={channel}
						mortgage={mortgage}></TableBankinter>
				);
			}
			if (valueRadio === 2 && idBank === 'a021t000009OgpJAAS') {
				content = (
					<TableSabadell mortgageId={mortgageId} type={channel} mortgage={mortgage}></TableSabadell>
				);
			}
			if (valueRadio === 1 && idBank === 'a021t000009OgpJAAS') {
				content = (
					<EmailPageSendBankSabadell
						onCreate={onCreate}
						onCancel={onCancel}
						mortgageName={mortgageName}
						mortgage={mortgage}
						channel={channel}
						mortgageId={mortgageId}
						mortgageData={mortgageData}></EmailPageSendBankSabadell>
				);
			}
			return content;
		} else {
			return (
				<div
					style={{
						textAlign: 'center',
						height: '80vh',
						backgroundColor: 'white'
					}}>
					<LoaderLogo />
				</div>
			);
		}
	}
};
export default ControllerApiEmailCreate;
