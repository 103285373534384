import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Input,
	Modal,
	Form,
	Select,
	Checkbox,
	Spin,
	notification
} from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputNumber, InputDate } from 'afrodita';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;

type Participant = {
	name: string;
	surname: string;
	phone: string;
	email: string;
	documentType: 'ES_NIF' | 'ES_NIE' | 'ES_CIF';
	documentNumber: string;
	incomes: number;
	expenses: number;
	address: {
		zip: string;
		city: string;
		street?: string;
	};
	activity:
		| 'EMPLOYEE_FIXED'
		| 'EMPLOYEE_TEMPORAL'
		| 'FREELANCER'
		| 'BUSINESS_OWNER'
		| 'RENTIER'
		| 'PENSIONER'
		| 'UNEMPLOYED'
		| 'FUNCTIONARY'
		| 'FUNCTIONARY_INTERIM'
		| 'UNKNOW';
	birthdate: string;
	seniority: number;
	educationLevel?: string;
	isGuarantor: boolean;
	isMainOwner: boolean;
};

type Payload = {
	operationId: string;
	bankRequestId: string;
	data: {
		operation: {
			type: string;
			externalId: string;
			data?: {
				offeredPrice?: number;
				approvalRequired?: boolean;
			};
			comments?: string;
			riskManager: {
				name: string;
				email: string;
				phone: string;
			};
		};
		mortgage?: {
			houseType: 'main' | 'second' | 'investor' | 'other';
			houseState: 'have_decide' | 'searching';
			propertyType: 'newConstruction' | 'secondHand';
			purchaseForecast: 'lessThanSixMonths' | 'withinSixOrTwelveMonths' | 'whitinOneOrTwoYears';
			price: number;
			appraisal: number;
			mortgage: number;
			terms: number;
			savings: number;
			address: {
				zip: string;
				city: string;
				street?: string;
			};
		};
		subrogation?: {
			dateEndMortgage: string;
			dateSign: string;
			amount: number;
			reason: 'improveMortgage' | 'increaseCapitalRenovations' | 'increaseCapitalLiquidity';
			purchaseForecast: 'moreThanOneYear' | 'lessThanOneYear';
			price: number;
			tin: number;
			rate: 'FIXED' | 'VARIABLE' | 'MIXED';
			address: {
				zip: string;
				city: string;
				street?: string;
			};
		};
		owners: Participant[];
		offer: {
			interest: 'FIXED' | 'VARIABLE' | 'MIXED';
			tinInitial: number;
			quoteInitial: number;
			tinFinal?: number;
			quoteFinal?: number;
			linkages: {
				payrollDomiciliation: boolean;
				homeInsurance: boolean;
				lifeInsurance: boolean;
				protectedPayments: boolean;
			};
		};
	};
};

const TableBankinter = ({ mortgageId, mortgage }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const operationType = mortgage?.operation?.subtype?.toUpperCase() || 'MORTAGE';
	const [visibleCancel, setVisibleCancel] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const location = useLocation();
	const bankRequestId = location?.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest =
		location && location.state !== undefined ? location.state.bankRequest : undefined;
	const [emptyFields, setEmptyFields] = useState<string[]>([]);
	const [desiredInterest, setDesiredInterest] = useState<string | undefined>(undefined);
	const [riskManager, setRiskManager] = useState<any | undefined>(undefined);
	const activityOptions = [
		{ label: 'Autónomo', value: 'FREELANCER' },
		{ label: 'Empresario', value: 'BUSINESS_OWNER' },
		{ label: 'Pensionista', value: 'PENSIONER' },
		{ label: 'Desempleado', value: 'UNEMPLOYED' },
		{ label: 'Rentista', value: 'RENTIER' },
		{ label: 'Asalariado fijo', value: 'EMPLOYEE_FIXED' },
		{ label: 'Asalariado temporal', value: 'EMPLOYEE_TEMPORAL' },
		{ label: 'Funcionario', value: 'FUNCTIONARY' },
		{ label: 'Funcionario interino', value: 'FUNCTIONARY_INTERIM' },
		{ label: 'Desconocido', value: 'UNKNOW' }
	];

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/mortgages/agents/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setRiskManager(response.data.data.analyst);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (mortgage) {
			handleFormChange();
		}
	}, [form, mortgage]);

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(
				([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
			)
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};

	const onFinish = (values: any) => {
		try {
			setLoading(true);
			const documentTypes = {
				ID: 'ES_NIF',
				FOREIGN_ID: 'ES_NIE',
				OTHER: 'ES_CIF'
			};
			const houseStates = {
				yes: 'have_decide',
				no: 'searching'
			};
			const createParticipant = (index: number): Participant => ({
				name: values[`owner${index}.name`],
				surname: values[`owner${index}.surname`],
				phone: values[`owner${index}.phone`],
				email: values[`owner${index}.email`],
				documentType: documentTypes[values[`owner${index}.idDocumentType`]],
				documentNumber: values[`owner${index}.nif`],
				incomes: values[`owner${index}.incomes`],
				expenses: values[`owner${index}.expenses`],
				address: {
					zip: values[`owner${index}.postalCode`],
					city: values[`owner${index}.population`],
					street: values[`owner${index}.address`]
				},
				educationLevel: values[`owner${index}.educationLevel`],
				activity: values[`owner${index}.activity`],
				birthdate: moment(values[`owner${index}.birthdate`], 'DD-MM-YYYY').isValid()
					? moment(values[`owner${index}.birthdate`], 'DD-MM-YYYY').format('YYYY-MM-DD')
					: undefined,
				seniority: values[`owner${index}.seniority`],
				isGuarantor: false,
				isMainOwner: true
			});
			const participantsArray: Participant[] = [];
			const owners = mortgage.operation.owners || 1;
			for (let i = 1; i <= owners; i++) {
				participantsArray.push(createParticipant(i));
			}

			const payload: Payload = {
				operationId: mortgageId,
				bankRequestId: bankRequestId,
				data: {
					operation: {
						type: operationType,
						externalId: mortgageId,
						data: {
							offeredPrice: values.offeredPrice,
							approvalRequired: values.approvalRequired
						},
						comments: values.comments,
						riskManager: {
							name: riskManager.name,
							email: riskManager.email,
							phone: riskManager.phone
						}
					},
					...(operationType === 'MORTGAGE'
						? {
								mortgage: {
									houseType: values.houseType,
									houseState: houseStates[values.chooseProperty],
									propertyType: values.propertyType,
									purchaseForecast: values.purchaseForecast,
									price: Number(values.maxBudget),
									appraisal: Number(values.appraisal),
									mortgage: Number(values.mortgageAmount),
									terms: Number(values.terms * 12),
									savings: Number(values.savings),
									address: {
										zip: values.postalCode,
										city: values.population,
										street: values.address
									}
								}
						  }
						: {
								subrogation: {
									dateEndMortgage: moment(values.dateEndMortgage, 'DD-MM-YYYY').isValid()
										? moment(values.dateEndMortgage, 'DD-MM-YYYY').format('YYYY-MM-DD')
										: undefined,
									dateSign: moment(values.dateSign, 'DD-MM-YYYY').isValid()
										? moment(values.dateSign, 'DD-MM-YYYY').format('YYYY-MM-DD')
										: undefined,
									amount: Number(values.amount),
									reason: values.reason,
									purchaseForecast: values.whenSignedMortgage,
									price: Number(values.maxBudget),
									tin: Number(values.haveATin),
									rate: values.interest.toUpperCase(),
									address: {
										zip: values.postalCode,
										city: values.population,
										street: values.address
									}
								}
						  }),
					owners: participantsArray,
					offer: {
						interest: values.desiredInterest,
						tinInitial: values.tinInitial,
						quoteInitial: values.quoteInitial,
						tinFinal: values.tinFinal,
						quoteFinal: values.quoteFinal,
						linkages: {
							payrollDomiciliation: values.payrollDomiciliation,
							homeInsurance: values.homeInsurance,
							lifeInsurance: values.lifeInsurance,
							protectedPayments: values.protectedPayments
						}
					}
				}
			};

			const cleanPayload = (obj: Partial<Payload>): Partial<Payload> =>
				_.omitBy(
					_.mapValues(obj, (value) =>
						Array.isArray(value)
							? value
							: _.isObject(value)
							? cleanPayload(value as Partial<Payload>)
							: value
					),
					(value) =>
						_.isNil(value) ||
						(typeof value === 'string' && value === '') ||
						(_.isObject(value) && _.isEmpty(value))
				);

			axios
				.post(
					`${env.api.url}/banks/sabadell_onebank/create-request`,
					{ ...cleanPayload(payload) },
					{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
				)
				.then((response) => {
					if (response.data.success) {
						setLoading(false);
						form.resetFields();
						notification.success({
							message: 'Solicitud generada correctamente',
							description: 'La solicitud de Sabadell se ha generado con éxito.'
						});
						setTimeout(() => {
							navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
							window.location.reload();
						}, 2000);
					} else {
						setLoading(false);
						notification.error({
							message: 'Error al generar la solicitud',
							description: 'Hubo un problema al generar la solicitud.'
						});
					}
				})
				.catch((error) => {
					console.log('Error: ', error);
					setLoading(false);
					notification.error({
						message: 'Error al generar la solicitud',
						description: 'Hubo un problema al generar la solicitud.'
					});
				});
		} catch (error) {
			console.log('Error: ', error);
			setLoading(false);
			notification.error({
				message: 'Error al generar la solicitud',
				description: 'Hubo un problema al generar la solicitud.'
			});
		}
	};

	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	const handleOk = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};

	const renderFields = (prefix: string) => (
		<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].name`, undefined)}
					name={`${prefix}.name`}
					label="Nombre"
					className={emptyFields.includes(`${prefix}.name`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={
						(_.get(mortgage, `[${prefix}].firstSurname`) || '') +
							(_.get(mortgage, `[${prefix}].lastSurname`)
								? ` ${_.get(mortgage, `[${prefix}].lastSurname`)}`
								: '') || undefined
					}
					name={`${prefix}.surname`}
					label="Apellidos"
					className={emptyFields.includes(`${prefix}.surname`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].phone`, undefined)}
					name={`${prefix}.phone`}
					label="Teléfono"
					className={emptyFields.includes(`${prefix}.phone`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].email`, undefined)}
					name={`${prefix}.email`}
					label="Email"
					className={emptyFields.includes(`${prefix}.email`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].idDocumentType`, undefined)}
					name={`${prefix}.idDocumentType`}
					label="Tipo de documento"
					className={emptyFields.includes(`${prefix}.idDocumentType`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Select placeholder="Seleccione" onChange={handleFormChange}>
						<Option key="ID" value="ID">
							DNI
						</Option>
						<Option key="FOREIGN_ID" value="FOREIGN_ID">
							NIE
						</Option>
						<Option key="OTHER" value="OTHER">
							Otro
						</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].nif`, undefined)}
					name={`${prefix}.nif`}
					label="DNI"
					className={emptyFields.includes(`${prefix}.nif`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={
						moment(_.get(mortgage, `[${prefix}].birthdate`, undefined), 'YYYY-MM-DD').isValid()
							? moment(_.get(mortgage, `[${prefix}].birthdate`, undefined), 'YYYY-MM-DD').format(
									'DD-MM-YYYY'
							  )
							: undefined
					}
					name={`${prefix}.birthdate`}
					label="Fecha de nacimiento"
					className={emptyFields.includes(`${prefix}.birthdate`) ? 'shadowed' : ''}
					rules={[
						{
							required: true,
							message: 'Por favor, rellene este campo o introduzca una fecha válida'
						}
					]}>
					<InputDate type="date" />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].address`, undefined)}
					name={`${prefix}.address`}
					label="Dirección">
					<Input />
				</Form.Item>
			</Col>
			<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].population`, undefined)}
					label="Población"
					name={`${prefix}.population`}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].postalCode`, undefined)}
					name={`${prefix}.postalCode`}
					label="Código postal"
					className={emptyFields.includes(`${prefix}.postalCode`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].activity`, undefined)}
					name={`${prefix}.activity`}
					label="Situación laboral"
					className={emptyFields.includes(`${prefix}.activity`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<Select options={activityOptions} placeholder="Seleccione" onChange={handleFormChange} />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].employment.educationLevel`, undefined)}
					name={`${prefix}.educationLevel`}
					label="Nivel de estudios">
					<Input />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(mortgage, `[${prefix}].seniority`, undefined)}
					name={`${prefix}.seniority`}
					label="Antigüedad laboral"
					className={emptyFields.includes(`${prefix}.seniority`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<InputNumber gbType="number" className="gb-input-new-years" />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.get(
						mortgage,
						`[${prefix}].incomesWealth.proRataMonthlyNetIncome`,
						undefined
					)}
					name={`${prefix}.incomes`}
					label="Ingresos"
					className={emptyFields.includes(`${prefix}.incomes`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<InputNumber gbType="currency" />
				</Form.Item>
			</Col>
			<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
				<Form.Item
					initialValue={_.sum([
						_.get(mortgage, `[${prefix}].loanPayment`, 0),
						_.get(mortgage, `[${prefix}].pensionPayment`, 0),
						_.get(mortgage, `[${prefix}].mortgagePayment`, 0)
					])}
					name={`${prefix}.expenses`}
					label="Gastos"
					className={emptyFields.includes(`${prefix}.expenses`) ? 'shadowed' : ''}
					rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
					<InputNumber gbType="currency" />
				</Form.Item>
			</Col>
		</Row>
	);

	const renderOwnersForm = () => {
		const owners = mortgage.operation.owners || 1;
		const arrayForm = [];

		for (let i = 1; i <= owners; i++) {
			arrayForm.push(
				<React.Fragment key={`owner-${i}`}>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: 24
							}}>
							{`Titular ${i}`}
						</Text>
					</Row>
					{renderFields(`owner${i}`)}
				</React.Fragment>
			);
		}

		return arrayForm;
	};

	return (
		<>
			{mortgage && !loading ? (
				<Form
					key={'sabadellForm'}
					form={form}
					layout="vertical"
					name="sendMessageForm"
					className="gb-form-offers-new"
					style={{ width: '100%' }}
					onChange={handleFormChange}
					onFinish={onFinish}
					scrollToFirstError={true}
					initialValues={{
						...(operationType === 'MORTGAGE'
							? _.get(mortgage, 'propertyWishPurchase')
							: _.get(mortgage, 'subrogation')),
						mortgageAmount: _.get(bankRequest, 'amount', undefined),
						amount: _.get(bankRequest, 'amount', undefined),
						savings: _.get(bankRequest, 'savings', undefined),
						dateEndMortgage: moment(
							_.get(mortgage, 'subrogation.dateEndMortgage', undefined),
							'YYYY-MM-DD'
						).isValid()
							? moment(
									_.get(mortgage, 'subrogation.dateEndMortgage', undefined),
									'YYYY-MM-DD'
							  ).format('DD-MM-YYYY')
							: undefined,
						dateSign: moment(
							_.get(mortgage, 'subrogation.dateSign', undefined),
							'YYYY-MM-DD'
						).isValid()
							? moment(_.get(mortgage, 'subrogation.dateSign', undefined), 'YYYY-MM-DD').format(
									'DD-MM-YYYY'
							  )
							: undefined,
						desiredInterest: _.get(bankRequest, 'rateType', undefined),
						quoteInitial: _.get(mortgage, 'desiredOffer.estimatedQuote', undefined),
						tinInitial:
							(_.get(mortgage, ['desiredOffer', 'sections', 0, 'tin']) &&
								_.get(mortgage, ['desiredOffer', 'sections', 0, 'tin']) * 100) ||
							undefined,
						tinFinal:
							(_.get(mortgage, ['desiredOffer', 'sections', 1, 'tin']) &&
								_.get(mortgage, ['desiredOffer', 'sections', 1, 'tin']) * 100) ||
							undefined,
						homeInsurance: _.get(mortgage, 'desiredOffer.linkages.homeInsurance', undefined),
						lifeInsurance: _.get(mortgage, 'desiredOffer.linkages.lifeInsurance', undefined),
						payrollDomiciliation: _.get(
							mortgage,
							'desiredOffer.linkages.payrollDomiciliation',
							undefined
						),
						protectedPayments: _.get(
							mortgage,
							'desiredOffer.linkages.protectedPayments',
							undefined
						),
						comments: _.get(mortgage, 'otherDataAdditional.otherData', undefined),
						terms:
							(_.get(bankRequest, 'months', undefined) &&
								_.get(bankRequest, 'months', undefined) / 12) ||
							undefined
					}}>
					<Row>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
							Datos de la operación
						</Text>
					</Row>
					<Row gutter={[32, 4]} style={{ marginTop: 24, marginBottom: 24 }}>
						<Col xs={24} md={8} sm={12} lg={3} xl={3} xxl={3}>
							<Form.Item label="Precio ofrecido" name={'offeredPrice'}>
								<InputNumber gbType="currency" />
							</Form.Item>
						</Col>
						<Col xs={23} md={8} sm={12} lg={3} xl={3} xxl={3}>
							<Form.Item label="Aprobación" name={'approvalRequired'}>
								<Select placeholder="Seleccione">
									<Option value={true}>Si</Option>
									<Option value={false}>No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={18}>
							<Form.Item label="Comentarios" name={'comments'}>
								<TextArea rows={3} style={{ width: '100%', height: 40 }} />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							Datos de la {operationType === 'MORTGAGE' ? 'hipoteca' : 'subrogación'}
						</Text>
					</Row>
					<Row gutter={[32, 4]} style={{ marginTop: 24, marginBottom: 24 }}>
						{operationType === 'MORTGAGE' ? (
							<>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item label="Uso previsto de la propiedad" name={'houseType'}>
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											<Option value="main">Vivienda habitual</Option>
											<Option value="second">Segunda residencia</Option>
											<Option value="investor">Inversión</Option>
											<Option value="other">Otros</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item label="¿Tiene la vivienda elegida?" name={'chooseProperty'}>
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											<Option value="yes">Sí</Option>
											<Option value="no">No</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item label="Tipo de propiedad" name={'propertyType'}>
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											<Option value="newConstruction">Obra nueva</Option>
											<Option value="secondHand">Segunda mano</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Precio de la propiedad"
										name={'maxBudget'}
										className={emptyFields.includes('maxBudget') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputNumber gbType="currency" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="¿Cuándo tiene pensado comprar?"
										name={'purchaseForecast'}
										className={emptyFields.includes('purchaseForecast') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											<Option value="lessThanSixMonths">{'En menos de 6 meses'}</Option>
											<Option value="withinSixOrTwelveMonths">Dentro de 6-12 meses</Option>
											<Option value="whitinOneOrTwoYears">En un plazo de 1 año o más</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item label="Valor de tasación" name={'appraisal'}>
										<InputNumber gbType="currency" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Importe financiado"
										name={'mortgageAmount'}
										className={emptyFields.includes('mortgageAmount') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputNumber gbType="currency" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item label="Plazo" name={'terms'}>
										<InputNumber gbType="number" className="gb-input-new-years" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Ahorros"
										name={'savings'}
										className={emptyFields.includes('savings') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputNumber gbType="currency" />
									</Form.Item>
								</Col>
							</>
						) : (
							<>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label={'¿Por qué quiere cambiar?'}
										className={emptyFields.includes('reason') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
										name="reason">
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											<Option value="improveMortgage">Mejora de condiciones</Option>
											<Option value="increaseCapitalRenovations">
												Ampliar capital para reforma
											</Option>
											<Option value="increaseCapitalLiquidity">
												Ampliar liquidez para capital
											</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label={'¿Hace cuánto firmó la hipoteca?'}
										className={emptyFields.includes('whenSignedMortgage') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
										name="whenSignedMortgage">
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											<Option value="moreThanOneYear">Más de un año</Option>
											<Option value="lessThanOneYear">Menos de un año</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Fecha de fin"
										name={'dateEndMortgage'}
										className={emptyFields.includes('dateEndMortgage') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputDate type="date" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Fecha de firma"
										name={'dateSign'}
										className={emptyFields.includes('dateSign') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputDate type="date" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Precio de compra-venta"
										name={'maxBudget'}
										className={emptyFields.includes('maxBudget') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputNumber gbType="currency" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Capital pendiente"
										name={'amount'}
										className={emptyFields.includes('pendingCapital') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputNumber gbType="currency" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label="Tipo de interés actual"
										name={'interest'}
										className={emptyFields.includes('interest') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											<Option value="fixed">Fijo</Option>
											<Option value="variable">Variable</Option>
											<Option value="mixed">Mixto</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
									<Form.Item
										label={'TIN (%)'}
										name={'haveATin'}
										className={emptyFields.includes('haveATin') ? 'shadowed' : ''}
										rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
										<InputNumber gbType="number" className="gb-input-new-percent" />
									</Form.Item>
								</Col>
							</>
						)}
						<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
							<Form.Item label="Dirección de la vivienda" name={`address`}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
							<Form.Item label="Población" name={'population'}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} md={8} sm={12} lg={6} xl={6} xxl={6}>
							<Form.Item
								label="Código Postal"
								name={'postalCode'}
								className={emptyFields.includes('postalCode') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							Datos de los titulares
						</Text>
					</Row>
					{renderOwnersForm()}
					<Row>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
							Datos de la oferta deseada
						</Text>
					</Row>
					<Row gutter={[32, 4]} style={{ marginTop: 24, marginBottom: 24 }}>
						<Col xs={24} md={8} sm={12} lg={4} xl={4} xxl={4}>
							<Form.Item
								label="Tipo de interés deseado"
								name={'desiredInterest'}
								className={emptyFields.includes('desiredInterest') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									placeholder={'Seleccione'}
									onChange={(value: string) => {
										setDesiredInterest(value);
										handleFormChange();
									}}>
									<Option value="FIXED">Fijo</Option>
									<Option value="VARIABLE">Variable</Option>
									<Option value="MIXED">Mixto</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} md={8} sm={12} lg={4} xl={4} xxl={4}>
							<Form.Item
								label="Cuota primer tramo"
								name={'quoteInitial'}
								className={emptyFields.includes('quoteInitial') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber gbType="currency" />
							</Form.Item>
						</Col>
						<Col xs={24} md={8} sm={12} lg={4} xl={4} xxl={4}>
							<Form.Item
								label="TIN primer tramo"
								name={'tinInitial'}
								className={emptyFields.includes('tinInitial') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber gbType="number" className="gb-input-new-percent" max={1} />
							</Form.Item>
						</Col>
						{desiredInterest && desiredInterest !== 'FIXED' && (
							<>
								<Col xs={24} md={8} sm={12} lg={4} xl={4} xxl={4}>
									<Form.Item label="Cuota segundo tramo" name={'quoteFinal'}>
										<InputNumber gbType="currency" />
									</Form.Item>
								</Col>
								<Col xs={24} md={8} sm={12} lg={4} xl={4} xxl={4}>
									<Form.Item label="TIN segundo tramo" name={'tinFinal'}>
										<InputNumber gbType="number" className="gb-input-new-percent" />
									</Form.Item>
								</Col>
							</>
						)}
						<Col span={24} style={{ marginTop: 6 }}>
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}>Vinculaciones</Text>
							<div style={{ display: 'flex', gap: 12, marginTop: 8 }}>
								<Form.Item name="payrollDomiciliation" valuePropName="checked">
									<Checkbox>
										<Text style={{ fontSize: 14 }}>Nómina</Text>
									</Checkbox>
								</Form.Item>
								<Form.Item name="homeInsurance" valuePropName="checked">
									<Checkbox>
										<Text style={{ fontSize: 14 }}>Hogar</Text>
									</Checkbox>
								</Form.Item>
								<Form.Item name="lifeInsurance" valuePropName="checked">
									<Checkbox>
										<Text style={{ fontSize: 14 }}>Vida</Text>
									</Checkbox>
								</Form.Item>
								<Form.Item name="protectedPayments" valuePropName="checked">
									<Checkbox>
										<Text style={{ fontSize: 14 }}>Protección de pagos</Text>
									</Checkbox>
								</Form.Item>
							</div>
						</Col>
					</Row>
					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={handleCancel}
							type="dashed">
							Atras
						</Button>
						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Aceptar
						</Button>
					</Row>
				</Form>
			) : (
				<>
					<div style={{ textAlign: 'center', height: '40vh' }}>
						<Spin style={{ position: 'absolute', top: '30%' }} size="large" />
					</div>
					<div style={{ textAlign: 'center', height: '80vh' }}>
						<Text style={{ marginTop: 34, fontSize: 14, fontWeight: 500 }}>
							Por favor, no cierre ni recargue la pagina, puede tardar hasta 3 minutos...
						</Text>
					</div>
				</>
			)}
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: 96 } }}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96 } }}
				open={visibleCancel}
				title={
					<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
						{'Cancelar envío'}
					</Text>
				}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				width={404}
				onCancel={handleCancel}
				onOk={handleOk}>
				<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858', marginTop: 6 }}>
					{'¿Aceptas la cancelación del envio a Sabadell?'}
				</Text>
			</Modal>
		</>
	);
};
export default TableBankinter;
