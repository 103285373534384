import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, message, Modal, Row, Select } from 'antd';
import axios from 'axios';
import authClient from '../../Auth/Auth';
import env from '../../environment';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import { useDispatch } from 'react-redux';
import { updateMotivoCiere } from '../../store/FormalizationTasacion/actions';
import { changeStatusJob } from '../../store/FormalizationTasacion/actions';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Option } = Select;

const MortgageClose = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [editorState, setEditorState] = useState(BraftEditor.createEditorState());
	const { loadData } = props;
	const [banksList, setBanksList] = useState(undefined);
	const [state, setState] = useState({
		requestReason: 'Seleccione',
		reasonsFinish: '',
		errorDetails: '',
		optionClient: '',
		optionHp: '',
		optionBank: '',
		closeAllTask: '',
		bankReasons: '',
		notInterested: '',
		scoring: '',
		submotive: ''
	});
	const [signDate, setSignDate] = useState(undefined);

	useEffect(() => {
		getBanksList();
	}, []);

	const getBanksList = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-banks`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data.sort(function (a, b) {
					if (a.bankCode > b.bankCode) {
						return 1;
					}
					if (a.bankCode < b.bankCode) {
						return -1;
					}
					return 0;
				});
				setBanksList(response.data.data);
			});
	};

	const closeCertificate = async () => {
		const htmlContent = editorState.toHTML();
		let subCloseReasonX = ' ';
		if (state.scoring) {
			subCloseReasonX = state.scoring;
		}
		if (state.notInterested) {
			subCloseReasonX = state.notInterested;
		}
		if (state.bankReasons) {
			subCloseReasonX = state.bankReasons;
		}
		if (state.sub) {
			subCloseReasonX = state.bankReasons;
		}

		axios.post(
			`${env.api.url}/v1/mortgages/data/multiple/update/${props.mortgageId.id}`,
			{
				values: [
					{
						key: 'certificate',
						subkey: 'ringTheBell',
						value: true
					}
				]
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);

		await axios
			.post(
				`${env.api.url}/v1/hadmin/operation-close`,
				{
					operationId: props.mortgageId.id,
					applicant:
						state.requestReason === 'Por solicitud del cliente'
							? 'client'
							: null || state.requestReason === 'Por solicitud de Gibobs'
							? 'h1'
							: null || state.requestReason === 'Por solicitud del banco'
							? 'bank'
							: null,
					closeReason: state.optionClient
						? state.optionClient
						: null || state.optionHp
						? state.optionHp
						: null || state.optionBank
						? state.optionBank
						: null,
					subCloseReason: subCloseReasonX,
					body: htmlContent,
					closeAllTask: state.closeAllTask
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				loadData();
				navigate('/certificates/list/active');
			});

		message.success('Su operación se ha cerrado correctamente.');

		if (props.onSuccess) {
			props.onSuccess();
		}
		setState({
			requestReason: 'Seleccione',
			reasonsFinish: '',
			errorDetails: '',
			optionClient: '',
			optionHp: '',
			optionBank: '',
			closeAllTask: '',
			bankReasons: '',
			notInterested: '',
			scoring: '',
			submotive: ''
		});

		// dispatch(changeStatusJob("finish"));
		setEditorState(BraftEditor.createEditorState());
	};
	const closeMortgage = async () => {
		const htmlContent = editorState.toHTML();
		let subCloseReasonX = ' ';

		if (state.scoring) {
			subCloseReasonX = state.scoring;
		}
		if (state.bankReasons) {
			subCloseReasonX = state.bankReasons;
		}
		if (state.notInterested) {
			subCloseReasonX = state.notInterested;
		}
		if (state.submotive) {
			subCloseReasonX = state.submotive;
		}

		const obj1 = {
			key: 'signDate',
			participant: 'mortgage',
			value: signDate
		};
		const obj2 = {
			mortgageId: props.mortgageId,
			applicant:
				state.requestReason === 'Por solicitud del cliente'
					? 'client'
					: null || state.requestReason === 'Por solicitud de Gibobs'
					? 'h1'
					: null || state.requestReason === 'Por solicitud del banco'
					? 'bank'
					: null,
			closeReason: state.optionClient
				? state.optionClient
				: null || state.optionHp
				? state.optionHp
				: null || state.optionBank
				? state.optionBank
				: null,
			subCloseReason: subCloseReasonX,
			body: htmlContent,
			closeAllTask: state.closeAllTask
		};

		if (state.optionHp === 'Reconocida a gibobs') {
			await axios.post(
				`${env.api.url}/v1/mortgages/data/update/${props.mortgageId}`,
				{ key: 'signDate', participant: 'mortgage', value: signDate },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			);
		}

		await axios
			.post(
				`${env.api.url}/api/hadmin/mortgage-close`,
				{
					mortgageId: props.mortgageId,
					applicant:
						state.requestReason === 'Por solicitud del cliente'
							? 'client'
							: null || state.requestReason === 'Por solicitud de Gibobs'
							? 'h1'
							: null || state.requestReason === 'Por solicitud del banco'
							? 'bank'
							: null,
					closeReason: state.optionClient
						? state.optionClient
						: null || state.optionHp
						? state.optionHp
						: null || state.optionBank
						? state.optionBank
						: null,
					subCloseReason: subCloseReasonX,
					body: htmlContent,
					closeAllTask: state.closeAllTask
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				//loadData();

				window.location.reload(false);
			});

		message.success('Su operación se ha cerrado correctamente.');

		if (props.onSuccess) {
			props.onSuccess();
		}
		setState({
			requestReason: 'Seleccione',
			reasonsFinish: '',
			errorDetails: '',
			optionClient: '',
			optionHp: '',
			optionBank: '',
			closeAllTask: '',
			bankReasons: '',
			notInterested: '',
			scoring: '',
			submotive: ''
		});

		dispatch(changeStatusJob('finish'));
		setEditorState(BraftEditor.createEditorState());
	};

	const handleEditorChange = (editorState2) => {
		setEditorState(editorState2);
	};

	const callRedux = async (key, value) => {
		let questionUpdateObj = {
			[key]: value
		};
		updateMotivoCiere(1, 1, questionUpdateObj);
	};

	const onChangeInput = (e, key) => {
		const value = e;

		setState({
			...state,
			[key]: value
		});

		if (key === 'requestReason') {
			setState({
				requestReason: value,
				reasonsFinish: '',
				errorDetails: '',
				optionClient: '',
				optionHp: '',
				optionBank: '',
				closeAllTask: '',
				bankReasons: '',
				notInterested: '',
				scoring: '',
				closeAllTask: state.closeAllTask,
				submotive: ''
			});
		}
	};

	const [form] = Form.useForm();
	const selectSubReasonsScoringAndBank = (name, value) => {
		return (
			<Select
				defaultValue="Seleccione"
				name={`${name}`}
				onChange={(e) => onChangeInput(e, `${name}`)}
				style={{ width: 300 }}
				onBlur={(e) => {
					callRedux(`${name}`, value);
				}}>
				<Option value="Ahorros insuficientes">Ahorros insuficientes</Option>
				<Option value="Tipo de vivienda">Tipo de vivienda</Option>
				<Option value="Riesgo reputacional">Riesgo reputacional</Option>
				<Option value="Ratio de endeudamiento">Ratio de endeudamiento</Option>
				<Option value="Tipo de contrato">Tipo de contrato</Option>
				<Option value="Antigüedad laboral">Antigüedad laboral</Option>
				<Option value="Edad">Edad</Option>
				<Option value="Incidencias en ASNEF">Incidencias en ASNEF</Option>
				<Option value="Riesgo de divisas">Riesgo de divisas</Option>
				<Option value="Ingresos demasiado bajos">Ingresos demasiado bajos</Option>
				<Option value="Importe de hipoteca demasiado bajo">
					Importe de hipoteca demasiado bajo
				</Option>
				<Option value="Política bancaria sin especificar">Política bancaria sin especificar</Option>
				<Option value="Capital inicial superior al 80% de LTC">
					Capital inicial superior al 80% de LTC
				</Option>
				<Option value="Ya ha tenido una novación/mejora anterior">
					Ya ha tenido una novación/mejora anterio
				</Option>
				<Option
					disabled={props.type !== 'subrogation' && props.type !== 'green-subrogation'}
					value="Insuficiente plazo transcurrido">
					Insuficiente plazo transcurrido
				</Option>
				<Option
					disabled={props.type !== 'subrogation' && props.type !== 'green-subrogation'}
					value="Insuficiente capital amortizado">
					Insuficiente capital amortizado
				</Option>
			</Select>
		);
	};

	return (
		<Modal
			className="gb-modal-style-common"
			open={props.visible}
			cancelButtonProps={{ className: 'button-secundary-gibobs' }}
			okButtonProps={{ className: 'button-primari-gibobs' }}
			title="Cerrar hipoteca"
			okText="Cerrar"
			onCancel={() => {
				props.onCancel();
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						if (props.type === 'certificate') {
							closeCertificate(values);
						} else {
							closeMortgage(values);
						}
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
			width="85%"
			centered>
			<Form form={form} layout="vertical">
				<Row gutter={24}>
					<Col span={24}>
						<Form.Item
							label={
								<strong>¿Quieres cerrar también las tareas asociadas a esta operación?</strong>
							}
							// style={{ marginTop: 20 }}
							name="NotesPostpone"
							rules={[
								{
									required: true,
									message:
										'Debes seleccionar si deseas cerrar las tareas asociadas a esta operación'
								}
							]}>
							<Select
								name="closeAllTask"
								onChange={(e) => onChangeInput(e, 'closeAllTask')}
								style={{ width: 300 }}
								placeholder="Seleccione">
								<Option value={true}>Si</Option>
								<Option value={false}>No</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={24}>
						<Form.Item
							label={<strong>Solicitante de Finalización</strong>}
							// style={{ marginTop: 20 }}
							name="requestReason"
							rules={[
								{
									required: true,
									message: 'Debes seleccionar quien solicita el cierre'
								}
							]}>
							<Select
								value={state.requestReason}
								name="requestReason"
								onChange={(e) => onChangeInput(e, 'requestReason')}
								style={{ width: 300 }}
								onBlur={() => {
									callRedux('requestReason', state.requestReason);
								}}>
								<Option value="Por solicitud del cliente">Por solicitud del cliente</Option>
								<Option value="Por solicitud de Gibobs">Por solicitud de Gibobs</Option>
								<Option
									value="Por solicitud del banco"
									disabled={props.type === 'certificate' ? true : false}>
									Por solicitud del banco
								</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				{state.requestReason === 'Por solicitud del cliente' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Motivos de Finalización</strong>}
								// style={{ marginTop: 20 }}
								name="optionClient"
								rules={[
									{
										required: true,
										message: 'Debes seleccionar un motivo de cierre'
									}
								]}>
								<Select
									defaultValue="Seleccione"
									name="optionClient"
									onChange={(e) => onChangeInput(e, 'optionClient')}
									style={{ width: 300 }}
									onBlur={(e) => {
										callRedux('optionClient', state.optionClient);
									}}>
									<Option
										value="Continúa con otro banco"
										disabled={props.type === 'certificate' ? true : false}>
										Continúa con otro banco
									</Option>
									<Option
										value="Continúa con otro prescriptor"
										disabled={props.type === 'certificate' ? true : false}>
										Continúa con otro prescriptor
									</Option>
									<Option
										value="Solo curioseaba"
										disabled={props.type === 'certificate' ? true : false}>
										Solo curioseaba
									</Option>
									<Option value="No interesado">No interesado</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				) : null}

				{state.optionClient === 'Continúa con otro banco' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Submotivo: Indicar el banco</strong>}
								// style={{ marginTop: 20 }}
								name="submotive">
								<Select
									defaultValue="Seleccione"
									name="submotive"
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={(e) => onChangeInput(e, 'submotive')}
									style={{ width: 300 }}
									onBlur={(e) => {
										callRedux('submotive', state.submotive);
									}}>
									{banksList
										? banksList.map((nameBank) => {
												return (
													<Option key={nameBank.id} value={nameBank.name}>
														{nameBank.name}
													</Option>
												);
										  })
										: null}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				) : null}

				{state.optionClient === 'Continúa con otro prescriptor' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Submotivo: Indicar el prescriptor</strong>}
								// style={{ marginTop: 20 }}
								name="submotive">
								<Select
									defaultValue="Seleccione"
									name="submotive"
									showSearch
									onChange={(e) => onChangeInput(e, 'submotive')}
									style={{ width: 300 }}
									onBlur={(e) => {
										callRedux('submotive', state.submotive);
									}}>
									<Option value="Iahorro">Iahorro</Option>
									<Option value="Helloteca">Helloteca</Option>
									<Option value="Hipoo">Hipoo</Option>
									<Option value="Housers">Housers</Option>
									<Option value="Idealista">Idealista</Option>
									<Option value="Housfy">Housfy</Option>
									<Option value="Rastreator">Rastreator</Option>
									<Option value="Trioteca">Trioteca</Option>
									<Option value="Finteca">Finteca</Option>
									<Option value="Otros">Otros</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				) : null}

				{state.requestReason === 'Por solicitud de Gibobs' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Motivos de Finalización</strong>}
								// style={{ marginTop: 20 }}
								name="optionClient"
								rules={[
									{
										required: true,
										message: 'Debes seleccionar un motivo de cierre'
									}
								]}>
								<Select
									defaultValue="Seleccione"
									name="optionHp"
									onChange={(e) => onChangeInput(e, 'optionHp')}
									style={{ width: 300 }}
									onBlur={(e) => {
										callRedux('optionHp', state.optionHp);
									}}>
									<Option value="Pre-scoring">Pre-scoring</Option>
									<Option
										value="No mejoramos su oferta"
										disabled={props.type === 'certificate' ? true : false}>
										No mejoramos su oferta
									</Option>
									<Option
										value="Perfil falso"
										disabled={props.type === 'certificate' ? true : false}>
										Perfil falso
									</Option>
									<Option value="Duplicado" disabled={props.type === 'certificate' ? true : false}>
										Duplicado
									</Option>
									<Option value="Pruebas">Pruebas</Option>
									<Option
										value="Ilocalizable"
										disabled={props.type === 'certificate' ? true : false}>
										Ilocalizable
									</Option>
									<Option
										value="Reconocida a gibobs"
										disabled={props.type === 'certificate' ? true : false}>
										Reconocida a gibobs
									</Option>
									<Option
										value="B. Sabadell - Compra en Andalucía"
										disabled={props.type === 'certificate' ? true : false}>
										B. Sabadell - Compra en Andalucía
									</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				) : null}
				{state.optionHp === 'Reconocida a gibobs' && (
					<Form.Item
						label={<strong>Submotivo</strong>}
						name="submotive"
						rules={[
							{
								required: true,
								message: 'Debes seleccionar un submotivo de cierre'
							}
						]}>
						<Select
							defaultValue="Seleccione"
							name="submotive"
							onChange={(e) => onChangeInput(e, 'submotive')}
							style={{ width: 300 }}
							onBlur={(e) => {
								callRedux('submotive', state.submotive);
							}}>
							<Option value="Reconocidas">Reconocidas</Option>
							<Option value="Firmada ex-post">Firmada ex-post</Option>
							<Option value="Ex-post aprobada">Ex-post aprobada</Option>
							<Option value="Reconocida aprobada">Reconocida aprobada</Option>
						</Select>
					</Form.Item>
				)}
				{state.requestReason === 'Por solicitud del banco' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Motivos de Finalización</strong>}
								// style={{ marginTop: 20 }}
								name="optionClient"
								rules={[
									{
										required: true,
										message: 'Debes seleccionar un motivo de cierre'
									}
								]}>
								<Select
									defaultValue="Seleccione"
									name="optionBank"
									onChange={(e) => onChangeInput(e, 'optionBank')}
									style={{ width: 300 }}
									onBlur={(e) => {
										callRedux('optionBank', state.optionBank);
									}}>
									<Option value="Inviable en bancos">Inviable en bancos</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				) : null}

				{state.optionHp === 'Pre-scoring' && state.requestReason === 'Por solicitud de Gibobs' ? (
					<Row style={{ paddingTop: 15, paddingBottom: 15 }}>
						<Col span={8}>{selectSubReasonsScoringAndBank('scoring', state.scoring)}</Col>
					</Row>
				) : null}

				{state.optionBank === 'Inviable en bancos' &&
				state.requestReason === 'Por solicitud del banco' ? (
					<Row style={{ paddingTop: 15, paddingBottom: 15 }}>
						<Col span={8}>{selectSubReasonsScoringAndBank('bankReasons', state.bankReasons)}</Col>
					</Row>
				) : null}

				{state.optionClient === 'No interesado' &&
				state.requestReason === 'Por solicitud del cliente' ? (
					<Row style={{ paddingTop: 15, paddingBottom: 15 }}>
						<Col span={8}>
							<Select
								showSearch
								defaultValue="Seleccione"
								name="notInterested"
								onChange={(e) => onChangeInput(e, 'notInterested')}
								style={{ width: 300 }}
								onBlur={(e) => {
									callRedux('notInterested', state.notInterested);
								}}>
								<Option
									value="Decide no comprar"
									disabled={props.type === 'certificate' ? true : false}>
									Decide no comprar
								</Option>
								<Option
									value="No busca hipoteca"
									disabled={props.type === 'certificate' ? true : false}>
									No busca hipoteca
								</Option>
								<Option value="No quiere compartir documentación">
									No quiere compartir documentación
								</Option>
								<Option value="No quiere nuestro servicio (no explica por qué)">
									No quiere nuestro servicio (no explica por qué)
								</Option>
							</Select>
						</Col>
					</Row>
				) : null}
				{state.optionHp === 'No mejoramos su oferta' &&
				state.requestReason === 'Por solicitud de Gibobs' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Submotivo: Indicar el banco</strong>}
								// style={{ marginTop: 20 }}
								name="submotive">
								<Select
									defaultValue="Seleccione"
									name="submotive"
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={(e) => onChangeInput(e, 'submotive')}
									style={{ width: 300 }}
									onBlur={(e) => {
										callRedux('submotive', state.submotive);
									}}>
									{banksList
										? banksList.map((nameBank) => {
												return (
													<Option key={nameBank.id} value={nameBank.name}>
														{nameBank.name}
													</Option>
												);
										  })
										: null}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				) : null}
				{state.optionHp === 'Reconocida a gibobs' &&
				state.requestReason === 'Por solicitud de Gibobs' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Fecha de firma</strong>}
								rules={[
									{
										required: true,
										message: 'Debes seleccionar una fecha'
									}
								]}
								name="signDate">
								<DatePicker
									onChange={(e) => setSignDate(dayjs(e).format('YYYY-MM-DD'))}
									format="DD-MM-YYYY"
									style={{ borderColor: '#c0daee', width: 300, borderRadius: 4 }}
								/>
							</Form.Item>
						</Col>
					</Row>
				) : null}
				{state.optionHp === 'B. Sabadell - Compra en Andalucía' &&
				state.requestReason === 'Por solicitud de Gibobs' ? (
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item
								label={<strong>Submotivo</strong>}
								name="submotive"
								rules={[
									{
										required: true
									}
								]}>
								<Select
									defaultValue="Seleccione"
									name="submotive"
									onChange={(e) => onChangeInput(e, 'submotive')}
									style={{ width: 300 }}
									onBlur={(e) => {
										callRedux('submotive', state.submotive);
									}}>
									<Option value="B. Sabadell - Compra en Andalucía">
										B. Sabadell - Compra en Andalucía
									</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				) : null}

				<Form.Item label="Contenido" style={{ minHeight: '40vh' }}>
					<BraftEditor language="en" value={editorState} onChange={handleEditorChange} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
export default MortgageClose;
